import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
      <div className="d-flex justify-content-center align-items-center" id="main">
          <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
          <div className="inline-block align-middle">
              <h2 className="font-weight-normal lead" id="desc">La page que vous cherchez n'a pas été trouvée.</h2>
          </div>
      </div>
  </Layout>
)

export default NotFoundPage
